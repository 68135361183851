<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row>
        <b-col class="mb-3 text-right">
          <b-link variant="primary" :to="{ name: 'Shipment' }">Spedisci</b-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-tabs content-class="mt-3">
            <b-tab title="BRT / DPD">
              <manifest courier-key="brt" courier-name="BRT / DPD" />
            </b-tab>
            <b-tab title="FedEx">
              <manifest courier-key="fdx" courier-name="FedEx" />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import Manifest from "@/components/Manifest.vue";

export default {
  name: "ShipmentsList",
  components: { Manifest }
};
</script>
