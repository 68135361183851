<template>
  <img class="logo-container" :src="getPath" :alt="name" />
</template>

<script>
export default {
  name: "CourierLogo",
  props: {
    name: {
      type: String,
      default: ""
    }
  },
  computed: {
    getPath: function() {
      return `/img/courier_logos/${this.name}.svg`;
    }
  }
};
</script>

<style scoped>
.logo-container {
  width: 200px;
  height: 100px;
  object-fit: contain;
  margin: 10px;
}
</style>
